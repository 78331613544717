const { experiments } = window.viewerModel
if (experiments['specs.thunderbolt.performanceTool']) {
	// @ts-ignore
	window.openPerformanceTool = () => {
		const openPerformanceToolWindow = window.open('https://performancetool.vercel.app/', '_blank')
		window.addEventListener('message', (event) => {
			if (event.data === 'opened') {
				openPerformanceToolWindow!.postMessage(
					{ type: 'performanceData', data: JSON.parse(JSON.stringify(window.performance.getEntries())) },
					'*'
				)
			}
		})
	}
}
